.dark-mode {
    background-color: black;
}
.light-mode {
    background-color: #f5f5f5;
}
textarea:focus,
textarea:focus-visible {
    color: #d5e0d8 !important;
    border-color: 2px solid #6f7e8c !important;
    /* border: 5px solid green !important; */
}
textarea:hover {
    border: 1px solid green !important ;
}
