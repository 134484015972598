.card {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.card-front {
    z-index: 2;
    transform: rotateY(0deg);
}

.card-back {
    transform: rotateY(180deg);
    z-index: 1;
}

.card:hover .card-front {
    transform: rotateY(-180deg);
}

.card:hover .card-back {
    transform: rotateY(0deg);
}
