/* #textarea {
    color: #d5e0d8 !important;
    font-family: IBM Plex Sans, sans-serif !important ;
    padding: 12px !important;
    resize: vertical !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.8 !important;
    border-radius: 4px 4px 4px 4px !important;
    background: black !important;
    border: 1px solid grey !important;
}

#textarea:hover {
    border-color: green !important;
}

#textarea:focus {
    color: #d5e0d8 !important;
    border: 2px solid #6f7e8c !important;
} */

.textarea-autosize,
input {
    color: #d5e0d8 !important;
}

/* textareaStyles.css */
.textarea-autosize {
    color: #d5e0d8 !important;
    font-family: IBM Plex Sans, sans-serif !important ;
    padding: 12px !important;
    resize: vertical !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.8 !important;
    border-radius: 4px 4px 4px 4px !important;
    background: black !important;
    border: 1px solid grey !important;
}
.textarea-autosize-light {
    color: black !important;
    font-family: IBM Plex Sans, sans-serif !important ;
    padding: 12px !important;
    resize: vertical !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.8 !important;
    border-radius: 4px 4px 4px 4px !important;
    background: whitesmoke !important;
    border: 1px solid grey !important;
}

.textarea-autosize:hover {
    border: green !important;
}

.textarea-autosize:focus {
    color: black;
    border: 1px solid grey !important;
}
.textarea-autosize-light:focus {
    color: black !important;
}
